import { Components, Theme } from "@mui/material/styles";
import { lightPalette } from "./palettes";
import { Colors } from "./colors";
import { fontWeightBold } from "./fontWeights";

export const overrides: Components<Theme> = {
  MuiInputBase: {
    styleOverrides: {
      input: {
        cursor: "inherit",
        "&::placeholder": {
          color: lightPalette.blue.darkShade1,
          opacity: "100%",
        },
      },
      root: {
        backgroundColor: lightPalette.white,
        borderColor: lightPalette.events.blueSecondary,
        "&:hover": {
          borderColor: lightPalette.events.blueSecondary,
        },
        "&.Mui-focused": {
          backgroundColor: lightPalette.white,
          borderColor: `${lightPalette.events.bluePrimary} !important`,
          "&:hover": {
            borderColor: lightPalette.events.bluePrimary,
          },
        },
        "&.Mui-disabled": {
          background: lightPalette.gray.disabledInput,
          borderColor: lightPalette.gray.light,
          color: `${lightPalette.blue.darkShade1}70 !important`,

          "&:hover": {
            cursor: "not-allowed",
            background: lightPalette.gray.disabledInput,
            borderColor: lightPalette.gray.light,
          },
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        zIndex: 10,
      },
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        minHeight: 0,
        padding: 0,
      },
      content: {
        margin: "0 !important",
        padding: 0,
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        margin: 0,
        padding: 0,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        padding: "0 30px",
        borderRadius: 5,
        textTransform: "none",
        fontWeight: fontWeightBold,
        fontSize: 16,
      },
      contained: {
        color: Colors.WHITE,
        backgroundColor: Colors.BLUE_PRIMARY,
        boxShadow: "none",
        "&:hover": {
          backgroundColor: Colors.BLUE_PRIMARY_HOVER,
          color: Colors.WHITE,
          boxShadow: "none",
        },
        "&.Mui-disabled": {
          backgroundColor: Colors.BLUE_LIGHTSHADE3_DISABLED,
          color: Colors.WHITE,
          boxShadow: "none",
        },
      },
      outlined: {
        borderColor: Colors.BLUE_PRIMARY,
        color: Colors.BLUE_PRIMARY,
        "&:hover": {
          backgroundColor: Colors.BLUE_LIGHTSHADE1,
        },
        "&.Mui-disabled": {
          borderColor: Colors.BLUE_LIGHTSHADE3_DISABLED,
          color: Colors.BLUE_LIGHTSHADE3_DISABLED,
        },
      },
      text: {
        color: Colors.BLUE_DARK,
        "&.Mui-disabled": {
          color: Colors.GRAY_DISABLED,
        },
      },
      startIcon: {
        pointerEvents: "none",
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: Colors.BLUE_DARKSHADE1,
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: 25,
        marginRight: 10,
        color: Colors.BLUE_PRIMARY,
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      container: {
        position: "relative",
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        color: Colors.BLUE_DARK,
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        "@media (max-width: 539.95px)": {
          margin: 15,
          width: "100% !important",
        },
      },
      paperScrollPaper: {
        "@media (max-width: 539.95px)": {
          maxHeight: "calc(100% - 70px)",
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        "&::after": {
          backgroundColor: "#EAEEF2",
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: "none",
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        whiteSpace: "normal",
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: {
        zIndex: 100_000,
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        "&.Mui-checked": {
          color: Colors.BLUE_PRIMARY,
          "&:hover": {
            backgroundColor: Colors.BLUE_PRIMARY[600],
          },
        },
      },
      switchBase: {
        color: Colors.BLUE_DARKSHADE1,
        "&:hover": {
          backgroundColor: Colors.BLUE_DARKSHADE1[600],
        },
      },
      track: {
        backgroundColor: Colors.GRAY_DISABLED,
      },
      input: {
        pointerEvents: "auto",
      },
    },
  },
};
