import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { IButtonProps } from "../types";

const DISABLED_BUTTON_OPACITY = 0.2;

export const useStyles = makeStyles<Theme, IButtonProps>((theme) => ({
  root: {
    borderRadius: "20px",
    boxSizing: "border-box",
    transition: "background-color .3s",
    height: "41px",
  },
  fullWidth: {
    width: "100%",
  },
  disabled: {
    pointerEvents: "none",
  },
  loading: {
    opacity: DISABLED_BUTTON_OPACITY,
  },
  contentWrapper: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    textTransform: "capitalize",
    padding: (props) =>
      props.variant === "text" || props.variant === "ghost"
        ? "0 !important"
        : "0 40px",
  },
  startAdornment: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  endAdornment: {
    marginRight: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    textAlign: "center",
    alignContent: "center",
    background: "transparent",
    alignItems: "center",
    fontWeight: (props) => (props.variant === "special" ? 500 : 700),
  },
  progressContainer: {
    display: "flex",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  sm: {
    fontSize: 14,
    padding: "0 20px !important",
  },
  md: {
    fontSize: 16,
  },
  primary: {
    backgroundColor: theme.palette.events.blueSecondary,
    color: theme.palette.events.whiteTertiary,
    boxShadow: "4px 4px 4px 0px #0C38911A",
    "&:hover,&.hover": {
      backgroundColor: theme.palette.events.blueAccent2,
    },
    "&:disabled,&.disabled": {
      boxShadow: "none",
      backgroundColor: theme.palette.events.grayPrimary,
      color: theme.palette.events.grayTertiary,
    },
  },
  secondary: {
    backgroundColor: theme.palette.events.bluePrimary,
    color: theme.palette.events.whiteTertiary,
    boxShadow: "4px 4px 4px 0px #0C38911A",
    "&:hover,&.hover": {
      backgroundColor: theme.palette.events.blueAccent3,
    },
    "&:disabled,&.disabled": {
      boxShadow: "none",
      backgroundColor: theme.palette.events.grayPrimary,
      color: theme.palette.events.grayTertiary,
    },
  },
  tertiary: {
    backgroundColor: theme.palette.events.whiteTertiary,
    color: theme.palette.events.blueSecondary,
    boxShadow: "4px 4px 4px 0px #0C38911A",
    "&:hover,&.hover": {
      color: theme.palette.events.blueAccent2,
    },
    "&:disabled,&.disabled": {
      boxShadow: "none",
      backgroundColor: theme.palette.events.grayPrimary,
      color: theme.palette.events.grayTertiary,
    },
  },
  special: {
    backgroundColor: theme.palette.events.greenAccent1,
    color: theme.palette.events.blueSecondary,
    boxShadow: "4px 4px 4px 0px #0C38911A",
    "&:hover,&.hover": {
      backgroundColor: theme.palette.events.greenAccent2,
      color: theme.palette.events.blueAccent2,
    },
    "&:disabled,&.disabled": {
      boxShadow: "none",
      backgroundColor: theme.palette.events.grayPrimary,
      color: theme.palette.events.grayTertiary,
    },
  },
  text: {
    backgroundColor: "transparent",
    color: theme.palette.events.blueSecondary,
    boxShadow: "none",
    "&:hover,&.hover": {
      color: theme.palette.events.blueAccent2,
      textDecoration: "underline",
    },
    "&:disabled,&.disabled": {
      color: theme.palette.events.grayTertiary,
    },
  },
  ghost: {
    backgroundColor: "transparent",
    color: theme.palette.events.blueSecondary,
    boxShadow: "none",
    "&:hover,&.hover": {
      color: theme.palette.events.blueAccent2,
    },
    "&:disabled,&.disabled": {
      color: theme.palette.events.grayTertiary,
    },
  },
}));
