import MUISwitch from "@mui/material/Switch";
import classNames from "classnames";
import { useEffect, useState, MouseEvent } from "react";
import { styled } from "@mui/material/styles";
import { useSwitchStyles } from "./styles";
import { SwitchProps } from "./types";

const SliderSwitch = styled(MUISwitch)(({ theme }) => ({
  width: 28,
  height: 15,
  padding: 0,
  display: "flex",
  "& .MuiSwitch-switchBase": {
    padding: "2px 0px",
    top: -1.1,
    transition: theme.transitions.create(["transform"], {
      duration: 200,
    }),
    "&.Mui-checked": {
      transform: "translateX(14px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.events.blueSecondary,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 13,
    height: 13,
    borderRadius: 8,
    backgroundColor: theme.palette.white,
    transition: theme.transitions.create(["transform", "background-color"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 10,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    opacity: 1,
    boxSizing: "border-box",
  },
}));

const Switch = (props: SwitchProps): JSX.Element => {
  const {
    checked = false,
    onClick,
    disabled,
    color = "primary",
    defaultChecked,
    size = "medium",
    required = false,
    darkMode = false,
    controlled = false,
    testId,
    ...rest
  } = props;
  const [checkedState, setCheckedState] = useState<boolean>(checked);
  const classes = useSwitchStyles();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    if (!controlled) {
      setCheckedState(!checkedState);
    }
  };

  const switchClasses = classNames({
    [classes.darkMode]: darkMode,
  });

  useEffect(() => {
    setCheckedState(checked);
  }, [checked]);

  return (
    <SliderSwitch
      checked={checkedState}
      onClick={handleClick}
      // @ts-expect-error testid prop works but not in interface
      inputProps={{ "aria-label": "switch", "data-testid": testId }}
      disabled={disabled}
      color={color}
      defaultChecked={defaultChecked}
      size={size}
      required={required}
      role="switch"
      data-testid="ui-core-switch"
      aria-checked={checkedState}
      className={switchClasses}
      {...rest}
    />
  );
};

export default Switch;
