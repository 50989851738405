import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { Theme } from "@remo-co/ui-core/src/types";

interface Props {
  hasPagination: boolean;
  width?: number;
}

export const useStyles = makeStyles<Theme, Props>((theme) => ({
  viewButton: ({ hasPagination }) => ({
    display: "flex",
    paddingRight: "10px",
    alignItems: "center",
    border: "none",
    background: "transparent",
    cursor: "pointer",
    padding: hasPagination ? "12px" : "5px",
  }),
  galleryIcon: {
    width: "19px",
    height: "19px",
  },
  label: {
    paddingLeft: "10px",
    fontSize: "16px",
    fontWeight: 800,
    lineHeight: "22px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  popoverContent: ({ width }) => ({
    backgroundColor: theme.palette.white,
    paddingTop: "10px",
    borderRadius: "6px",
    width: width && width > 260 ? "256px" : "100%",
  }),
  selectView: {
    padding: "0 10px",
    color: "inherit",
  },
  viewOptionButton: {
    display: "flex",
    alignItems: "center",
    padding: "5px",
    border: "none",
    background: "transparent",
    cursor: "pointer",
    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.events.bluePrimary,
    },
    "&:disabled": {
      color: "inherit",
    },
  },
  grayBackground: {
    backgroundColor: theme.palette.events.bluePrimary,
  },
  optionHeading: {
    fontSize: "14px",
    fontWeight: 500,
  },
  optionText: {
    textAlign: "start",
    paddingLeft: "10px",
  },
  layoutIcon: {
    width: "40px",
    "& svg": {
      color: "inherit",
    },
  },
  doneIcon: {
    color: `${theme.palette.events.whiteTertiary} !important`,
  },
}));
