import { DeprecatedThemeOptions } from "@mui/material/styles";
import { Colors } from "../colors";

const fontWeightRegular = 400;
const fontWeightMedium = 500;
const fontWeightBold = 600;

export const darkTypography: DeprecatedThemeOptions["typography"] = {
  fontFamily: [
    "Avenir",
    "Helvetica Neue",
    "Helvetica",
    "Arial",
    "sans-serif",
  ].join(","),
  fontSize: 14,
  fontWeightRegular,
  fontWeightMedium,
  fontWeightBold,
  h1: {
    fontWeight: fontWeightMedium,
    fontSize: 30,
  },
  h2: {
    fontWeight: fontWeightMedium,
    fontSize: 24,
  },
  h3: {
    fontWeight: fontWeightMedium,
    fontSize: 20,
  },
  h4: {
    fontWeight: fontWeightMedium,
    fontSize: 16,
  },
  h5: {
    fontWeight: fontWeightMedium,
    fontSize: 14,
  },
  h6: {
    fontWeight: fontWeightMedium,
    fontSize: 11,
  },
  body1: {
    fontWeight: fontWeightRegular,
    fontSize: 14,
  },
  body2: {
    fontWeight: fontWeightMedium,
    fontSize: 14,
  },
  caption: {
    fontWeight: fontWeightRegular,
    fontSize: 12,
  },
  subtitle1: {
    fontWeight: fontWeightRegular,
    fontSize: 14,
  },
  subtitle2: {
    fontWeight: fontWeightBold,
    fontSize: 14,
    color: Colors.BLUE_DARKSHADE1,
  },
  button: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 1.31,
  },
};
