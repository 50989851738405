import {
  Placement,
  autoUpdate,
  offset,
  useFloating,
} from "@floating-ui/react-dom-interactions";
import { useI18n } from "i18n";
import {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { selectLayout } from "modules/audioVideo/redux/selectors";
import { useSelector } from "react-redux";
import {
  setActiveTile,
  setIsLayoutManually,
  setLayout,
} from "modules/audioVideo/redux/slice";
import { useAppDispatch } from "store/hooks";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { ClickAwayListener } from "@remo-co/ui-core/src/components/ClickAwayListener";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { GalleryIcon } from "@remo-co/ui-core/src/icons/Gallery";
import { DoneIcon } from "@remo-co/ui-core/src/icons/TickIcon";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { DNS_KEYS } from "modules/globalUserPreference";
import { selectIsEventManager } from "modules/event/selectors";
import classNames from "classnames";
import { useLocalSessionId } from "@daily-co/daily-react";
import { selectCurrentUserPreferences } from "modules/userPreferences/redux/selectors";
import { selectActiveSpeaker } from "modules/daily/redux/selectors";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { useStyles } from "./styles";
import { layoutDetails } from "./constants";
import { DisplayItem, DisplayLayout, TileDraft } from "../Layouts";

interface Props {
  placement: Placement;
  tiles: TileDraft[];
  hasPagination: boolean;
  switchLayout?: (layout: DisplayLayout, showDialog?: boolean) => void;
}

const { disableSwitchScreenFocusModeNotification } = DNS_KEYS;

export const ChangeLayoutButton = ({
  placement,
  tiles,
  hasPagination,
  switchLayout,
}: Props): JSX.Element => {
  const [showLayoutMenu, setShowLayoutMenu] = useState(false);
  const currentLayout = useSelector(selectLayout);
  const { t } = useI18n(["common", "event"]);
  const dispatch = useAppDispatch();
  const isEventManager = useSelector(selectIsEventManager);
  const currentUserPreferences = useSelector(selectCurrentUserPreferences);
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const width = document.getElementById("layout-container")?.offsetWidth;
  const localSessionId = useLocalSessionId();
  const speaker = useSelector(selectActiveSpeaker);

  const styles = useStyles({
    hasPagination,
    width,
  });

  const isDNSDisabled = useMemo(
    () =>
      !(
        currentUserPreferences &&
        currentUserPreferences[disableSwitchScreenFocusModeNotification]
      ),
    [currentUserPreferences],
  );

  const handleLayout = useCallback(
    (layout: DisplayLayout) => {
      if (isInBroadcast && switchLayout) {
        if (isDNSDisabled && isEventManager) {
          dispatch(
            addDialogNotification({
              message: t("dialog.display.mode.message"),
              hideCloseButton: true,
              confirmText: t("yes"),
              dismissText: t("no"),
              className: "dark",
              onConfirm: () => switchLayout(layout, false),
              hasDoNotShowAgain: true,
              dnsKey: disableSwitchScreenFocusModeNotification,
            }),
          );
        } else {
          switchLayout(layout);
        }
      } else {
        dispatch(setLayout(layout));
        dispatch(setIsLayoutManually(true));
        if (layout === DisplayLayout.Focus) {
          if (speaker) {
            dispatch(
              setActiveTile({
                streamId: speaker,
                type: DisplayItem.camera,
                local: speaker === localSessionId,
              }),
            );
          } else {
            dispatch(setActiveTile(tiles[0]));
          }
        }
      }
    },
    [
      dispatch,
      isDNSDisabled,
      isEventManager,
      localSessionId,
      speaker,
      switchLayout,
      t,
      tiles,
      isInBroadcast,
    ],
  );
  const { x, y, floating, strategy, reference, refs } =
    useFloating<HTMLButtonElement>({
      whileElementsMounted: autoUpdate,
      placement,
      middleware: [offset({ mainAxis: 10, crossAxis: 0 })],
    });

  const openCloseMenu = (e: MouseEvent | TouchEvent | ReactMouseEvent) => {
    if (
      e.target instanceof Node &&
      !refs.reference.current?.contains(e.target)
    ) {
      setShowLayoutMenu(false);
    }
  };

  const layoutDetailsAvailable = useMemo(
    () =>
      layoutDetails.filter(
        (layout) => !(layout.id === DisplayLayout.Map && isInBroadcast),
      ),
    [isInBroadcast],
  );

  return (
    <>
      <button
        className={styles.viewButton}
        type="submit"
        onClick={() => setShowLayoutMenu(!showLayoutMenu)}
        ref={reference}
        data-testid="change-layout-button"
      >
        <GalleryIcon className={styles.galleryIcon} />
        <Typography color="textSecondary" className={styles.label}>
          {t("common:view")}
        </Typography>
      </button>
      {showLayoutMenu && (
        <ClickAwayListener onClickAway={openCloseMenu} mouseEvent="onMouseDown">
          <div
            ref={floating}
            style={{
              position: strategy,
              top: y ?? "",
              left: x ?? "",
              zIndex: 10,
            }}
            data-testid="change-layout-options"
          >
            <div className={styles.popoverContent}>
              <Container>
                <div className={styles.promptToSave}>
                  <Typography className={styles.selectView}>
                    {t("event:select.view")}
                  </Typography>
                </div>
                {layoutDetailsAvailable.map((layout) => {
                  if (layout.id === DisplayLayout.Map && isInBroadcast) {
                    return null;
                  }
                  const isCurrentLayout = layout.id === currentLayout;

                  return (
                    <button
                      key={layout.id}
                      className={`${styles.viewOptionButton} ${classNames({
                        [styles.grayBackground]: isCurrentLayout,
                      })}`}
                      type="submit"
                      onClick={() => handleLayout(layout.id)}
                      data-testid={`${layout.id}-button`}
                      disabled={layout.id === currentLayout}
                    >
                      <div className={styles.layoutIcon}>
                        {currentLayout === layout.id ? (
                          <DoneIcon className={styles.doneIcon} />
                        ) : (
                          layout.image
                        )}
                      </div>
                      <div className={styles.optionText}>
                        <Typography
                          className={styles.optionHeading}
                          color={isCurrentLayout ? "white" : ""}
                        >
                          {t(`event:${layout.heading}`)}
                        </Typography>
                        <Typography
                          className={styles.optionDescription}
                          color={isCurrentLayout ? "white" : ""}
                        >
                          {t(`event:${layout.description}`)}
                        </Typography>
                      </div>
                    </button>
                  );
                })}
              </Container>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};
