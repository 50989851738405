import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles((theme) => ({
  fileInput: {
    top: "48px",
  },
  iconButton: {
    color: theme.palette.events.blueSecondary,
  },
}));

export default useStyles;
