import { MouseEvent, ChangeEvent, useState } from "react";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { TextField } from "@remo-co/ui-core/src/components/TextField";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { useI18n } from "i18n";
import { CheckCircleOutline as AnsweredIcon } from "@remo-co/ui-core/src/icons/CheckCircleOutline";
import { ArrowDropUp as UpVoteIcon } from "@remo-co/ui-core/src/icons/ArrowDropUp";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Virtuoso } from "react-virtuoso";
import {
  askQuestion,
  questionAnswered,
  updateVoteForQuestion,
} from "../../firebase";
import { ConfirmationQADialog } from "../ConfirmationQADialog";
import { selectQuestions } from "../../redux/selectors";
import useStyles from "./styles";

interface Props {
  isEventManager: boolean;
  eventId: string;
  authorName: string;
  userId: string;
  setIsClickAwayListenerDisabled?: (isDisabled: boolean) => void;
}

export const CHARACTER_LIMIT = 200;

export const ANONYMOUS = "_anms_";

const QA = ({
  eventId,
  isEventManager,
  authorName,
  userId,
  setIsClickAwayListenerDisabled,
}: Props): JSX.Element => {
  const styles = useStyles();
  const { t } = useI18n(["event"]);
  const [message, setMessage] = useState<string>("");
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const questions = useSelector(selectQuestions);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const toggleDialog = () => {
    setConfirmDialog(!confirmDialog);
  };

  const handleConfirm = (data: { isAnonymous: boolean }) => {
    askQuestion(eventId, message, data.isAnonymous ? ANONYMOUS : authorName);
    setMessage("");
    toggleDialog();
  };

  const handleUpVote = (questionId: number) => {
    updateVoteForQuestion(eventId, questionId, userId);
  };

  const handleDownVote = (questionId: number) => {
    updateVoteForQuestion(eventId, questionId, userId, true);
  };

  const handleAnswered = (questionId: number) => {
    questionAnswered(eventId, questionId);
  };

  return (
    <div className={styles.container} id="qa-ctn">
      <div className={styles.layoutContainer}>
        {confirmDialog && (
          <ConfirmationQADialog
            onConfirm={handleConfirm}
            onCancel={toggleDialog}
            setIsClickAwayListenerDisabled={setIsClickAwayListenerDisabled}
          />
        )}
        <div className={styles.questionList}>
          {questions && (
            <Virtuoso
              className={styles.list}
              data={questions}
              overscan={2}
              defaultItemHeight={72}
              increaseViewportBy={200}
              // eslint-disable-next-line react/no-unstable-nested-components
              itemContent={(_, question) => {
                const isAlreadyVoted = !!(
                  question.voteCount && question.voters[userId]
                );
                // eslint-disable-next-line consistent-return
                const onUpvote = (e: MouseEvent, id: number) => {
                  if (isAlreadyVoted) {
                    return handleDownVote(id);
                  }
                  e.stopPropagation();
                  handleUpVote(id);
                };

                return (
                  <div
                    data-testid={question.id}
                    className={styles.questionListItem}
                    key={question.id}
                  >
                    <div className={styles.voteContainer} id="vote-ctn">
                      <Tooltip
                        title={t("event:upvotes") as string}
                        placement="top"
                      >
                        <span>
                          <IconButton
                            data-testid="qa-upvote"
                            size="small"
                            className={classNames(styles.voteButton, {
                              [styles.voted]: isAlreadyVoted,
                            })}
                            onClick={(e) => onUpvote(e, question.id)}
                          >
                            <UpVoteIcon
                              data-testid={`qa-upvote-${question.id}`}
                              className={styles.upVoteIcon}
                              fontSize="small"
                            />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Typography component="div" className={styles.voteCount}>
                        {question.voteCount}
                      </Typography>
                      <Typography component="div" className={styles.voteText}>
                        {t("event:votes")}
                      </Typography>
                    </div>
                    <div className={styles.questionDescription}>
                      <Typography variant="body2" className={styles.authorName}>
                        {question.authorName === ANONYMOUS
                          ? t("event:anonymous")
                          : question.authorName}
                      </Typography>
                      <Typography
                        color="inherit"
                        component="div"
                        className={styles.question}
                      >
                        {question.question}
                      </Typography>
                    </div>
                    {isEventManager && (
                      <div className={styles.answeredContainer}>
                        <div className={styles.answered}>
                          <Tooltip
                            title={t("event:mark.answered") as string}
                            placement="top"
                          >
                            <IconButton
                              data-testid="qa-answer-button"
                              size="medium"
                              onClick={() => handleAnswered(question.id)}
                            >
                              <AnsweredIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    )}
                  </div>
                );
              }}
            />
          )}
        </div>
        <div className={styles.askQuestionContainer}>
          <TextField
            id="outlined-qa-section"
            placeholder={t("event:enter.question")}
            fullWidth
            value={message}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
            inputProps={{
              "data-testid": "qa-ask-question-text-field",
              cols: 44,
              maxLength: CHARACTER_LIMIT,
            }}
            multiline
            maxRows={3}
            className={styles.questionTextField}
          />
          <Tooltip
            title={
              !message || !message.length
                ? (t("event:question.question") as string)
                : (t("event:ask.now") as string)
            }
            placement="top"
          >
            <Button
              data-testid="qa-ask-question-button"
              disabled={!message || !message.length}
              onClick={toggleDialog}
              className={styles.sendButton}
              disableRipple
              variant="secondary"
              contentWrapperClassName={styles.askButton}
            >
              <Typography variant="h4">{t("event:ask")}</Typography>
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default QA;
