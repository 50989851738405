import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Prompt, useHistory, useRouteMatch } from "react-router-dom";
import _ from "lodash";
import { useBeforeunload } from "react-beforeunload";
import { Location } from "history";
import { useI18n } from "i18n";
import { PageLoader } from "@remo-co/ui-core/src/components/PageLoader";
import { getEventData } from "services/apiService/apis";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import useEventValidator from "modules/event/hooks/useEventValidator";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { setNewEventId } from "modules/event/eventSlice";
import UpgradePlanPopup from "modules/upgradePlan/UpgradePlanPopup";
import logger from "logging/logger";
import { selectCompany } from "modules/company/redux/selectors";
import { EVENT_MODAL_STATE } from "modules/eventForm/createEvent/CreateEditConstants";
import eventsApi from "modules/eventForm/events.api";
import { isFormDirty } from "helpers/formHelper";
import { fetchWithTimeoutAndRetry } from "helpers/fetchWithTimeoutAndRetry";
import { usePrevious } from "helpers/reactHooksUtils";
import templatesService from "services/firestoreService/templates";
import { getEventTypeOptions } from "services/firestoreService/eventTypes";
import { loadTemplate } from "modules/event/template";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { USERPILOT_CONTEXT } from "modules/userpilot/UserpilotContext";
import { getUnixTimestamp } from "helpers/time/format";
import { useSaveEvent } from "modules/event/hooks/useSaveEvent";
import { useCheckEventStatus } from "modules/event/hooks/useCheckEventStatus";
import { DEFAULT_FLOOR_PLAN, DEFAULT_THEME } from "modules/customFloorPlan";
import { DEFAULT_EVENT_CAPACITY, DEFAULT_EVENT_DATA } from "modules/event";
import { SetOutcome } from "modules/eventForm";
import { IEvent } from "modules/event/types";
import resetTextAndMediaDefault from "modules/manageEvent/utils/resetTextAndMediaDefault";
import {
  Callback,
  ManageEventContextValues,
  ManageEventMatchParams,
  ManageEventState,
  ValidatorResponseType,
} from "modules/manageEvent/hooks/types";
import getComputedStartEndTimes from "modules/manageEvent/utils/getComputedStartEndTimes";
import { useManageEvent, useManageEventReducer } from "modules/manageEvent";
import useManageEventTemplates from "modules/manageEvent/hooks/useManageEventTemplates";
import {
  resetLiveStream,
  setLiveStreams,
} from "modules/liveStream/redux/slice";
import { useFilteredTemplates } from "modules/customFloorPlan/hooks/useFilteredTemplates";
import {
  selectOnboardDefaultMedia,
  selectDiscoveryDisabledByDefault,
} from "modules/companySettings/redux/selectors";
import { usePageSettingReducerActions } from "modules/pageSetting/context/pageSettingReducer";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { BASIC_SETTINGS } from "../../constants";

export const MANAGE_EVENT_CONTEXT = createContext<ManageEventContextValues>({
  isStartTimeDisabled: false,
  isExpectedAttendanceDisabled: true,
  isGeneratingImage: false,
  isLoadingEvent: true,
  updateEventData: () => null,
  updatePartialEventData: () => null,
  updateInitialEventData: () => null,
  setLoading: () => null,
  loadingMessage: null,
  saveEvent: () =>
    new Promise((resolve) => {
      resolve(null);
    }),
  onTabChange: () => null,
  mode: null,
  actions: null,
  state: null,
  getTemplates: () => null,
});

const getUnsplashImage = async (): Promise<string> => {
  const FALL_BACK_URL =
    "https://images.unsplash.com/photo-1539146395724-de109483bdd2?h=490&w=780";

  // HoYin Unsplash collection Events
  const resp = await fetchWithTimeoutAndRetry(
    "https://source.unsplash.com/collection/9537244/780x490",
    { timeout: 350 },
    5,
  );

  return resp?.url ?? FALL_BACK_URL;
};

type Props = {
  eventId?: string;
  preDefinedEventData?: Partial<IEvent> | null;
  children: ReactNode;
};

// eslint-disable-next-line max-lines-per-function
const ManageEventContextProvider = ({
  eventId,
  preDefinedEventData,
  children,
}: Props): JSX.Element => {
  const match: ManageEventMatchParams = useRouteMatch();
  const defaultOnboardMedia = useSelector(selectOnboardDefaultMedia);
  const discoveryDisabledByDefault = useSelector(
    selectDiscoveryDisabledByDefault,
  );
  const history = useHistory();
  const [state, actions] = useManageEventReducer();
  const { setPageTitle } = usePageSettingReducerActions();
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const { onUpgradeClose } = useEventValidator();
  const saveEvent = useSaveEvent();
  const { checkIfEventHappeningNow } = useCheckEventStatus();
  const dispatch = useAppDispatch();
  const { t } = useI18n(["common", "manageEvent", "event", "server"]);
  const { addSuccessNotification, addErrorNotification } =
    useNotificationActions();
  const { company, companyPlan } = useSelector(selectCompany);
  const { track } = useContext(TRACKING_CONTEXT);
  const { trackUserpilotEvent } = useContext(USERPILOT_CONTEXT);
  const [isLoadingEvent, setLoadingEvent] = useState(true);
  const [loadingMessage, setLoading] = useState("");
  const [afterConfirm, setAfterConfirm] = useState<
    null | ((confirm?: boolean) => void)
  >(null);
  const [showSetOutcomeDialogBox, setShowOutcomeDialogBox] = useState(false);
  const [initialEventData, setInitialEventData] = useState<IEvent>();
  const [isEventChanged, setIsEventChanged] = useState(false);
  const [isStartTimeDisabled, setIsStartTimeDisabled] = useState(false);
  const [isExpectedAttendanceDisabled, setIsExpectedAttendanceDisabled] =
    useState(false);
  const query = new URLSearchParams(window.location.search);

  let eventState = EVENT_MODAL_STATE.CREATE;
  const eventSettingsTimeLimit = companyPlan?.settings?.event?.timeLimit ?? -1;
  const unlimitedEventsSettings = companyPlan?.settings?.unlimitedEvents;

  if (query.get("clone")) {
    eventState = EVENT_MODAL_STATE.CLONE;
  } else if (
    !query.get("clone") &&
    match &&
    match.params &&
    match.params.eventId
  ) {
    eventState = EVENT_MODAL_STATE.EDIT;
  }

  const [mode] = useState<EVENT_MODAL_STATE>(eventState);

  const { hasDirtyForm, tabValidator, templates } = state;
  const eventData = state.eventData ?? undefined;
  const { setEventData, updatePartialEventData } = actions;
  const prevEventData = usePrevious(eventData);

  useManageEventTemplates(eventData, templates, actions);
  const filteredTemplates = useFilteredTemplates(templates);

  const setDefaultMediaURLIfAbsent = (event: IEvent) => {
    if (event.welcomeMessage) {
      return {
        ...event,
        welcomeMessage: {
          ...event.welcomeMessage,
          mediaURL: event.welcomeMessage.mediaURL || defaultOnboardMedia.url,
        },
      };
    }

    return event;
  };

  const conformToDraftEventData = _.flow([
    resetTextAndMediaDefault,
    setDefaultMediaURLIfAbsent,
  ]);

  const {
    isValidData,
    getCloneData,
    handleAfterSaveData,
    loadSponsors,
    updateTemplateData,
  } = useManageEvent();
  const { upgradeMessage } = state as ManageEventState;

  useBeforeunload(() => (isEventChanged ? "You'll lose your data!" : false));

  useEffect(() => {
    const currentPage =
      (match && match.params && match.params.pageId) || BASIC_SETTINGS.pageId;

    actions.setPageId(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  useEffect(() => {
    let title = eventData?.isUnlimitedEvent
      ? t("manageEvent:create.new.space")
      : t("manageEvent:create.new.event");

    if (mode === EVENT_MODAL_STATE.CLONE) {
      title = t("manageEvent:cloning.event");
    } else if (mode === EVENT_MODAL_STATE.EDIT) {
      title = eventData?.isUnlimitedEvent
        ? t("manageEvent:edit.space")
        : t("manageEvent:edit.event");
    }
    setPageTitle(title);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, t, eventData?.isUnlimitedEvent]);

  useEffect(() => {
    if (initialEventData && checkIfEventHappeningNow(initialEventData)) {
      setIsStartTimeDisabled(true);
      setIsExpectedAttendanceDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialEventData]);

  const handleConfirmation = () => {
    actions.setHasDirtyForm(false);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    handleConfirmationClose(true);
  };

  /**
   * This method is called for both confirm and cancel
   * @param e
   * @param isConfirmed - will be passed only from confirm click
   */
  const handleConfirmationClose = (isConfirmed?: boolean) => {
    if (afterConfirm) {
      afterConfirm(isConfirmed);
    }
    setAfterConfirm(null);
  };

  /**
   *
   * @param cb
   * @param validator page level validator function
   */
  const onTabChange = (cb: Callback) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    checkIfFormDirty(cb, tabValidator);

    if (!hasDirtyForm) {
      handleConfirmation();
    }
  };

  /**
   * If form is dirty, show confirmation dialog otherwise do the callback
   * @param cb
   */
  const checkIfFormDirty = (
    cb: Callback,
    validator: ((cb?: boolean) => ValidatorResponseType) | null,
  ) => {
    if (hasDirtyForm) {
      dispatch(
        addDialogNotification({
          message: t("manageEvent:confirm.dialogue"),
          hideCloseButton: true,
          title: t("manageEvent:confirm.title"),
          onDismiss: handleConfirmation,
          onConfirm: handleConfirmationClose,
          confirmText: t("manageEvent:confirm.text.stay"),
          dismissText: t("manageEvent:dismiss.text.leave"),
          lightDialog: true,
        }),
      );
      setAfterConfirm(cb);
    } else {
      // If page level validator is sent, then validate the data
      let isValid = true;
      let response;

      if (validator) {
        response = validator();

        isValid = response.isValid;
      }

      if (!isValid && response) {
        dispatch(
          addDialogNotification({
            title: response.title,
            message: response.message,
            confirmText: response.confirmText,
            dismissText: response.cancelText,
            lightDialog: true,
            onConfirm: response.onConfirm,
            onDismiss: () => {
              cb(true);
            },
          }),
        );
      }

      if (isValid && cb) {
        cb(true);
      }
    }
  };

  const onSave = async () => {
    if (
      (eventData &&
        prevEventData &&
        !isValidData(eventData, actions, prevEventData, true)) ||
      company == null
    ) {
      return null;
    }

    const isEventOutcomeSet = Boolean(eventData?.eventOutcome);
    const isEventTypeSet = Boolean(eventData?.eventType);

    if (!isEventOutcomeSet || !isEventTypeSet) {
      setShowOutcomeDialogBox(true);
      return null;
    }

    let message = t("manageEvent:creating.event");

    if (mode === EVENT_MODAL_STATE.CLONE) {
      message = t("manageEvent:cloning.current.event");
    } else if (mode === EVENT_MODAL_STATE.EDIT) {
      message = t("manageEvent:saving.event");
    }
    setLoading(message);

    const updatedEventData = await saveEvent(
      _.cloneDeep({
        ...eventData,
        company: company.id,
        updatedAt: getUnixTimestamp(),
        contactChannels: (eventData?.contactChannels ?? []).filter(
          (channel) => channel.value.trim() !== "",
        ),
      }) as IEvent,
    );

    if (updatedEventData) {
      if (
        !updatedEventData.isOverflowSeatingDisabled &&
        initialEventData?.isOverflowSeatingDisabled
      ) {
        track(
          Events.TABLE_OVERFLOW_ENABLED,
          {
            eventId: updatedEventData.id,
          },
          {
            groupId: company.id,
          },
        );
      }

      if (
        updatedEventData.isOverflowSeatingDisabled &&
        !initialEventData?.isOverflowSeatingDisabled
      ) {
        track(
          Events.TABLE_OVERFLOW_DISABLED,
          {
            eventId: updatedEventData.id,
          },
          {
            groupId: company.id,
          },
        );
      }

      setInitialEventData(updatedEventData);
      updateTemplateData(updatedEventData, eventData);
      const isNewEvent = !eventData?.id;

      // If new event, show popup
      if (isNewEvent) {
        addSuccessNotification({
          message: t("event:event.key.success", {
            key: `${eventData?.name}`,
          }),
          position: "tr",
          autoDismiss: 5,
        });
        dispatch(setNewEventId(`${updatedEventData.id}`));

        handleAfterSaveData(state, actions, updatedEventData);
      } else {
        addSuccessNotification({
          message: t("manageEvent:event.save.success"),
          position: "tr",
          autoDismiss: 5,
        });
      }
      setEventData(updatedEventData);
      setIsEventChanged(false);
    }
    setLoading("");

    return updatedEventData;
  };

  const loadEventData = async (id: string) => {
    setLoading(t("manageEvent:details.loading"));
    setLoadingEvent(true);
    const data = await getEventData(id);

    if (data?.isSuccess && data?.event) {
      const normalizedEventData: IEvent = conformToDraftEventData(data.event);

      if (
        company &&
        normalizedEventData?.company &&
        // eslint-disable-next-line you-dont-need-lodash-underscore/is-string
        !_.isString(normalizedEventData?.company) &&
        normalizedEventData?.company?.id !== company?.id
      ) {
        addErrorNotification({
          message: t("manageEvent:different.company.event"),
        });
        history.push("/my-events");
        setLoadingEvent(false);
        setLoading("");

        return;
      }

      if (data?.eventLiveStreams) {
        dispatch(setLiveStreams(data.eventLiveStreams));
      }

      if (mode === EVENT_MODAL_STATE.EDIT) {
        setEventData(normalizedEventData);
        setInitialEventData(normalizedEventData);
        // eslint-disable-next-line promise/catch-or-return, promise/prefer-await-to-then
        loadSponsors(normalizedEventData).then((sponsorsData) =>
          actions.setSponsors(sponsorsData),
        );
      }

      if (mode === EVENT_MODAL_STATE.CLONE) {
        // Reset unwanted variables here
        const cloneEvent = await getCloneData(normalizedEventData);

        dispatch(resetLiveStream());
        setEventData(cloneEvent);
        setInitialEventData(cloneEvent);
        // eslint-disable-next-line promise/catch-or-return, promise/prefer-await-to-then
        loadSponsors(cloneEvent).then((sponsorsData) =>
          actions.setSponsors(sponsorsData),
        );
      }
    } else {
      addErrorNotification({
        message: data?.message || t("manageEvent:data.fetch.failure"),
      });
    }

    setLoadingEvent(false);
    setLoading("");
  };

  const loadAdditionalManageEventData = async () => {
    if (company?.id) {
      setLoading(t("loading"));

      const [publicTemplates, { privateTemplateCodes }, eventTypeOptions] =
        await Promise.all([
          templatesService.getPublicTemplates(),
          eventsApi.getTemplates(company.id),
          getEventTypeOptions(),
        ]);

      const privateTemplates = await templatesService.getTemplatesByCodes(
        privateTemplateCodes,
      );

      actions.setTemplates({ publicTemplates, privateTemplates });
      actions.setEventTypeOptions(eventTypeOptions);
      setLoading("");
    }
  };

  const getTemplates = async () => {
    if (company?.id) {
      setLoading(t("manageEvent:loading.floor.plan"));
      const [publicTemplates, { privateTemplateCodes }] = await Promise.all([
        templatesService.getPublicTemplates(),
        eventsApi.getTemplates(company.id),
      ]);
      const privateTemplates = await templatesService.getTemplatesByCodes(
        privateTemplateCodes,
      );
      if (eventId) {
        await loadEventData(eventId);
      }
      actions.setTemplates({ publicTemplates, privateTemplates });
      setLoading("");
    }
  };

  const initializeEventData = useCallback(async () => {
    setIsGeneratingImage(true);

    const template = await templatesService.getTemplateByCode(
      DEFAULT_FLOOR_PLAN,
    );

    if (!template) {
      logger.info(
        `[initializeEventData] not able to get template for ${DEFAULT_FLOOR_PLAN}`,
      );

      return;
    }

    const startEndTimes = await getComputedStartEndTimes({
      startTime: 0,
      endTime: 0,
      eventSettingsTimeLimit,
      isUnlimitedEvent: preDefinedEventData?.isUnlimitedEvent,
    });

    // Set initial default template
    let event: IEvent = {
      ...DEFAULT_EVENT_DATA,
      ...startEndTimes,
      id: "",
      description: "",
      isPrivate: false,
      logoURL: await getUnsplashImage(),
      name: "",
      isTextDefault: false,
      isMediaDefault: false,
      theaters: [
        {
          id: "",
          capacity: DEFAULT_EVENT_CAPACITY,
          theme: DEFAULT_THEME,
          template,
        },
      ],
      isDiscoveryOptedOut: discoveryDisabledByDefault ?? undefined,
      isUnlimitedEvent: false,
    };

    if (preDefinedEventData) {
      event = { ...event, ...preDefinedEventData };
      if (preDefinedEventData?.isUnlimitedEvent) {
        event = {
          ...event,
          theaters: [
            {
              id: "",
              capacity: unlimitedEventsSettings?.maxCapacity || 25,
              theme: DEFAULT_THEME,
              template,
            },
          ],
        };
      }
    }

    setIsGeneratingImage(false);

    await loadTemplate(event);
    setInitialEventData(event);
    setEventData(event);
  }, [
    eventSettingsTimeLimit,
    preDefinedEventData,
    discoveryDisabledByDefault,
    setEventData,
    unlimitedEventsSettings?.maxCapacity,
  ]);

  useEffect(() => {
    if (company) {
      if (eventId) {
        loadEventData(eventId);
      } else {
        setLoadingEvent(false);
        initializeEventData();
        track(Events.NEW_EVENT_VIEWED);
        trackUserpilotEvent(Events.NEW_EVENT_VIEWED);
      }
      loadAdditionalManageEventData();
      // Reset previously set new event ID
      dispatch(setNewEventId(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, companyPlan]);

  useEffect(() => {
    if (initialEventData && eventData) {
      const fieldsToOmit = ["provider"];

      const isDirty = isFormDirty(
        // eslint-disable-next-line you-dont-need-lodash-underscore/omit
        _.omit(initialEventData, fieldsToOmit),
        // eslint-disable-next-line you-dont-need-lodash-underscore/omit
        _.omit(eventData, fieldsToOmit),
      );

      setIsEventChanged(isDirty);

      if (isDirty && prevEventData) {
        // Validate and show error message when switching tabs
        isValidData(eventData, actions, prevEventData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData]);

  const updateEventData = (data: Partial<IEvent>) => {
    setEventData(
      _.omitBy({ ...eventData, ...data }, _.isNil) as unknown as IEvent,
    );
  };

  const updateInitialEventData = (data: IEvent) => {
    setInitialEventData(
      _.omitBy({ ...initialEventData, ...data }, _.isNil) as unknown as IEvent,
    );
  };

  const isLeavingChanges = (next: Location) => {
    const isLeaving = isEventChanged && !next.pathname.includes("/event");

    if (isLeaving) {
      dispatch(
        addDialogNotification({
          message: t("manageEvent:not.saved.warning"),
          lightDialog: true,
          title: t("manageEvent:warning.title"),
          confirmText: t("manageEvent:confirm.text"),
          dismissText: t("manageEvent:dismiss.text"),
          onConfirm: () => {
            // eslint-disable-next-line promise/catch-or-return, promise/prefer-await-to-then
            onSave().then((res) => {
              // eslint-disable-next-line promise/always-return
              if (res) {
                setIsEventChanged(false);
                setTimeout(() => {
                  history.push(next.pathname);
                }, 100);
              }
            });
          },
          onDismiss: () => {
            setIsEventChanged(false);
            setTimeout(() => {
              history.push(next.pathname);
            }, 100);
          },
        }),
      );
    }

    return !isLeaving;
  };

  const handleShowOutcomeDialogClose = useCallback(() => {
    setShowOutcomeDialogBox(false);
  }, []);

  return (
    <MANAGE_EVENT_CONTEXT.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        updateEventData,
        updateInitialEventData,
        updatePartialEventData, // meant for use only in cases where there can be outdated data within closures
        saveEvent: onSave,
        setLoading,
        isLoadingEvent,
        loadingMessage,
        onTabChange,
        mode,
        state,
        actions,
        isStartTimeDisabled,
        isExpectedAttendanceDisabled,
        isGeneratingImage,
        getTemplates,
        templates: filteredTemplates,
      }}
    >
      {children}
      {loadingMessage && <PageLoader message={loadingMessage} />}
      {showSetOutcomeDialogBox && (
        <SetOutcome onClose={handleShowOutcomeDialogClose} />
      )}
      <UpgradePlanPopup
        upgradeMessage={upgradeMessage}
        onClose={() => onUpgradeClose(() => actions.setUpgradeMessage(""))}
        onUpgrade={() => setIsEventChanged(false)}
      />
      <Prompt message={isLeavingChanges} />
    </MANAGE_EVENT_CONTEXT.Provider>
  );
};

export default ManageEventContextProvider;
