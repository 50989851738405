import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/types/types-external";
import { ActionObject } from "react-notification-system";

export type NotificationLevel = "success" | "error" | "warning" | "info";

export interface NotificationState {
  level?: NotificationLevel;
  title?: string;
  message?: string | JSX.Element;
  position?: "tr" | "tl" | "tc" | "br" | "bl" | "bc";
  autoDismiss?: number;
  action?: ActionObject;
  uid?: string;
  dismissible?: boolean;
}

export const initialState: NotificationState[] = [];

const addNotification =
  (level: NotificationLevel) =>
  (
    state: WritableDraft<NotificationState>[],
    { payload: notification }: PayloadAction<NotificationState>,
  ) => {
    state.push({
      ...notification,
      uid: notification.uid || Date.now().toString(),
      position: notification.position || "tr",
      autoDismiss: notification.autoDismiss ?? 6,
      level,
    });
  };

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addInfoNotification: addNotification("info"),
    addErrorNotification: addNotification("error"),
    addWarningNotification: addNotification("warning"),
    addSuccessNotification: addNotification("success"),
    removeNotification: (
      state: NotificationState[],
      { payload }: PayloadAction<string | number>,
    ) => state.filter((notification) => notification?.uid !== payload),
    removeAllNotifications: () => initialState,
  },
});

export const {
  addInfoNotification,
  addErrorNotification,
  addSuccessNotification,
  addWarningNotification,
  removeNotification,
  removeAllNotifications,
} = notificationSlice.actions;

export default notificationSlice.reducer;
