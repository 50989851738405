import { useRef, useEffect, useCallback } from "react";
import UploadService, {
  FILE_UPLOAD_STATUS,
  IAfterFileUpload,
} from "services/uploadService/uploadService";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import classNames from "classnames";
import { useChatActions } from "modules/chat";
import { selectUser } from "modules/auth/redux/selectors";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { AttachFile as AttachFileIcon } from "@remo-co/ui-core/src/icons/AttachFile";
import { IChannel, IChatMessage } from "modules/chat/types";
import { useI18n } from "i18n";
import { useSelector } from "react-redux";
import * as FirebaseStoragePaths from "services/firebaseService/storagePaths";
import { selectIsChatUploadEnabled } from "modules/event/selectors";
import { getUserName } from "modules/userProfile";
import { makeSelectActiveChannel } from "../../redux/selectors";
import useStyles from "./styles";

const ChatFileUploader = (): JSX.Element | null => {
  const styles = useStyles();
  const uploader = useRef<UploadService<IChannel>>();
  const { addErrorNotification, addInfoNotification } =
    useNotificationActions();
  const { sendMessage } = useChatActions();
  const { t } = useI18n(["common", "micCamCheck", "errorPage", "server"]);
  const user = useSelector(selectUser);
  const activeChannel = useSelector(makeSelectActiveChannel);
  const isChatUploadEnabled = useSelector(selectIsChatUploadEnabled);

  useEffect(() => {
    if (!isChatUploadEnabled) return undefined;

    uploader.current = new UploadService("sc-chat-uploader", {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      afterFileUpload,
      path: FirebaseStoragePaths.CHAT_MEDIA,
      level: FirebaseStoragePaths.UPLOAD_LEVEL.EVENT,
    });

    return () => {
      if (uploader.current) {
        uploader.current.setMarkedAsDestroyable();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatUploadEnabled]);

  const startAttachment = useCallback(() => {
    // Allow next upload only if first time upload or upload completed
    const fileUploadStatus = uploader.current?.getStatus();

    if (!fileUploadStatus || fileUploadStatus === FILE_UPLOAD_STATUS.END) {
      uploader.current?.browse(activeChannel);
    } else {
      addInfoNotification({
        message: t("micCamCheck:current.load.wait"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChannel, addInfoNotification]);

  const afterFileUpload: IAfterFileUpload<IChannel> = (
    err,
    channel,
    url,
    file,
    // eslint-disable-next-line max-params
  ) => {
    if (err) {
      addErrorNotification({ message: t(err.message) });

      return;
    }

    if (file && channel) {
      if (!url) {
        throw new Error(
          `Url not formed for file ${file.name} for user ${
            user && user.profile && getUserName(user)
          }`,
        );
      }
      sendMessage(channel, {
        type: "file",
        text: file.name,
        meta: { url, size: file.size, mime: file.type },
      } as unknown as IChatMessage);
    }
  };

  if (!isChatUploadEnabled) return null;

  return (
    <div data-testid="sc-chat-uploader">
      <IconButtonWithTooltip
        id="chat-attach-files"
        title={t("micCamCheck:attach.files")}
        onClick={startAttachment}
        className={styles.iconButton}
      >
        <AttachFileIcon />
      </IconButtonWithTooltip>
      {/** Filepond input for file uploads */}
      <div className={classNames(styles.fileInput, "file-uploader-container")}>
        <input
          id="sc-chat-uploader"
          type="file"
          name="filepond"
          data-testid="file-uploader-input"
        />
      </div>
    </div>
  );
};

export default ChatFileUploader;
