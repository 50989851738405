import { MouseEvent, useContext, useEffect, useMemo, useState } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Grid } from "@remo-co/ui-core/src/components/Grid";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { StatusIcon } from "@remo-co/ui-core/src/components/StatusIcon";
import { PageLoader } from "@remo-co/ui-core/src/components/PageLoader";
import { Autorenew } from "@remo-co/ui-core/src/icons/Autorenew";
import { Check } from "@remo-co/ui-core/src/icons/Check";
import { RemoveRedEye } from "@remo-co/ui-core/src/icons/RemoveRedEye";
import Img from "react-cool-img";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import { useI18n } from "i18n";
import classNames from "classnames";
import { getMapImageWithTheme } from "modules/theater/theater.helper";
import { getMapTemplateByType } from "modules/event/template";
import { IMapTemplate } from "types/theater";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { useAppDispatch } from "store/hooks";
import LayoutDelete from "../../customizeTheme/LayoutDelete";
import { useStyles } from "./styles";
import { FloorPlanWarning } from "../FloorPlanWarning";

interface Props {
  id?: string;
  template: IMapTemplate;
  themeName?: string;
  isAccountSettings?: boolean;
  onClick: (template: IMapTemplate, theme?: string) => void;
  onSelect?: (template: IMapTemplate, theme?: string) => void;
  showWarning?: boolean;
  maxSeats: number;
  showDelete?: boolean;
}

const FloorPlanLayoutItem = ({
  id,
  template,
  isAccountSettings,
  themeName,
  onClick,
  onSelect,
  showWarning,
  maxSeats,
  showDelete,
}: Props): JSX.Element => {
  const { state } = useContext(MANAGE_EVENT_CONTEXT);
  const { eventData } = state ?? {};
  const { name, rooms } = template;
  const [isImageLoading, setIsImageLoading] = useState(false);
  const { t } = useI18n(["common", "floorplans"]);
  const allowDelete = showDelete || isAccountSettings;
  const styles = useStyles({ allowDelete, isAccountSettings });
  const imageUrl = getMapImageWithTheme(template, themeName);
  const dispatch = useAppDispatch();

  const themeImageOnLoad = () => {
    setIsImageLoading(false);
  };

  useEffect(() => {
    setIsImageLoading(true);
  }, []);

  const isSelected = useMemo(() => {
    const eventTheater = eventData?.theaters?.[0];
    const evTemplate = getMapTemplateByType(eventTheater?.template);

    // Get selected theme name and template code
    const currentTemplateCode = evTemplate?.code;
    const currentTheme = eventTheater?.theme;

    if (currentTemplateCode && currentTemplateCode === template.code) {
      // If only current template has theme, then check theme name, otherwise it is selected
      return template.themes ? currentTheme === themeName : true;
    }

    return false;
  }, [eventData, themeName, template]);

  // If it is private floor plan and DISABLED and not used in current event
  // Dont allow host to select this floor plan
  const isDisabledFP = useMemo(() => {
    const isTemplatePrivate = template.isPrivate && template.isDisabled;

    return !isSelected && isTemplatePrivate;
  }, [isSelected, template]);

  const onSelectClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (isDisabledFP) {
      dispatch(
        addDialogNotification({
          message: t("floorplans:template.disabled"),
          hideDismissButton: true,
        }),
      );

      return;
    }

    if (onSelect) onSelect(template, themeName);
  };

  const hasAdminSeat = useMemo(() => {
    if (rooms && rooms.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const room of rooms) {
        if (room.adminSeats && room.adminSeats.length) {
          return true;
        }
      }
    }

    return false;
  }, [rooms]);

  const onLayoutClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onClick(template, themeName);
  };

  const ttlTitle: string = !isDisabledFP
    ? template.description || t("tooltip.view.floor.plan")
    : t("floorplans:template.short.disabled");

  return (
    <Tooltip title={ttlTitle} placement="top">
      <Grid
        item
        xs={2}
        data-testid="layout-grid"
        onClick={isAccountSettings ? onLayoutClick : onSelectClick}
        className={classNames(
          styles.container,
          { [styles.templateSelected]: isSelected },
          { [styles.templateDisabled]: isDisabledFP },
        )}
        id={id}
      >
        <div
          className={classNames(styles.media, {
            [styles.templateSelected]: isSelected,
          })}
          data-testid="layouts-item"
        >
          <Img
            decode={false} // Added this to fix decode error in Edge browser https://github.com/wellyshen/react-cool-img/issues/163
            debounce={500}
            height={120}
            onLoad={themeImageOnLoad}
            onError={themeImageOnLoad}
            src={imageUrl}
            alt={name}
            className={styles.themeImage}
            clGridassName={styles.templateImage}
          />
          {showWarning && (
            <Tooltip
              classes={{
                popper: styles.floorPlanTooltip,
                tooltip: styles.floorPlanTooltip,
              }}
              title={<FloorPlanWarning maxSeats={maxSeats} />}
              placement="bottom-start"
            >
              <Box
                className={styles.floorPlanWarning}
                data-testid="floor-plan-warning-box"
              >
                <StatusIcon variant="warning" />
              </Box>
            </Tooltip>
          )}
          <Typography
            variant="h5"
            data-testid="layouts"
            className={styles.templateName}
          >
            {name}
          </Typography>
          {isImageLoading && <PageLoader message={t("loading")} />}
          {!hasAdminSeat && (
            <Tooltip title={`${t("tooltip.absent.admin")}`} placement="top">
              <Autorenew className={styles.noAdminSeatsIcon} />
            </Tooltip>
          )}
        </div>
        <div
          className={classNames(styles.actions, {
            [styles.templateSelectedActions]: isSelected,
          })}
        >
          {allowDelete && (
            <div className={styles.deleteActionButton}>
              <LayoutDelete
                id={id}
                template={template}
                setLayoutLoading={() => setIsImageLoading(true)}
              />
            </div>
          )}
          <div className={styles.viewActionButton}>
            <Tooltip title={`${t("tooltip.title")}`} placement="top">
              <Button
                onClick={onLayoutClick}
                className={styles.actionButtons}
                data-testid="layout-preview-button"
                disabled={isDisabledFP}
                variant="ghost"
                color="dark"
              >
                <RemoveRedEye className={styles.actionButtonIcons} />
              </Button>
            </Tooltip>
          </div>
          {!isAccountSettings && (
            <div className={styles.selectActionButton}>
              <Tooltip
                title={isSelected ? "" : `${t("tooltip.select.floor.plan")}`}
                placement="top"
              >
                <Button
                  onClick={onSelectClick}
                  variant={isSelected ? "secondary" : "tertiary"}
                  color="blue"
                  isIconButton
                  id={`${id}-select-button`}
                  className={classNames(styles.actionButtons, {
                    [styles.isSelected]: isSelected,
                  })}
                  data-testid="template-select-button"
                >
                  <Check className={styles.actionButtonIcons} />
                </Button>
              </Tooltip>
            </div>
          )}
        </div>
      </Grid>
    </Tooltip>
  );
};

export default FloorPlanLayoutItem;
