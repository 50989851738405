import {
  ChangeEventHandler,
  ReactNode,
  MouseEvent as ReactMouseEvent,
  ComponentType,
  ElementType,
  FocusEventHandler,
  HTMLInputTypeAttribute,
  MouseEventHandler,
  forwardRef,
} from "react";
import classnames from "classnames";
import InputBase, { InputBaseComponentProps } from "@mui/material/InputBase";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import { styles } from "./styles";
import { LG, SM } from "../../../types";
import { InputHelpText } from "../../InputHelpText";
import { Typography } from "../../Typography";

export interface LightInputProps {
  id?: string;
  name?: string;
  className?: string;
  label?: string;
  size?: SM | LG | "multi";
  disabled?: boolean;
  labelComponent?: ComponentType;
  description?: string;
  component?: ElementType<InputBaseComponentProps>;
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number;
  error?: string | boolean;
  defaultValue?: unknown;
  value?: unknown;
  required?: boolean;
  autoFocus?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  inputProps?: InputBaseComponentProps;
  placeholder?: string;
  readOnly?: boolean;
  inputClassName?: string;
  inputEndAdornment?: ReactNode;
  inputStartAdornment?: ReactNode;
  onInputLabelClick?(event: ReactMouseEvent): void;
  inputFocused?: boolean;
  onClick?: MouseEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  type?: HTMLInputTypeAttribute;
  getRemainingCharsMessage?: (remainingChars: string) => string;
  descriptionClassName?: string;
}

const useStyles = makeStyles<Theme, LightInputProps>(styles);

const LightInput = forwardRef<HTMLInputElement, LightInputProps>(
  (props, ref) => {
    const {
      id,
      className,
      description,
      component = "input",
      fullWidth = false,
      multiline = false,
      rows,
      error = false,
      defaultValue,
      required = false,
      autoFocus = false,
      label,
      labelComponent: LabelComponent,
      inputProps,
      onChange,
      placeholder,
      value,
      readOnly,
      inputClassName = "",
      inputEndAdornment,
      inputStartAdornment,
      onInputLabelClick,
      inputFocused = false,
      disabled = false,
      getRemainingCharsMessage,
      descriptionClassName,
      size = "lg",
      ...rest
    } = props;

    const classes = useStyles(props);
    const currentLength = value ? `${value}`.length : 0;

    const rootClassName = classnames(
      {
        [classes.fullWidth]: fullWidth,
        [classes.error]: error,
      },
      classes.root,
      classes[size],
      className,
    );

    return (
      <div className={classes.container}>
        <div>
          {label && (
            <InputLabel htmlFor={id} onClick={onInputLabelClick}>
              <div
                className={classnames(classes.label, {
                  [classes.disabled]: disabled,
                })}
              >
                {label && (
                  <Typography variant="h5">
                    {required ? `${label}*` : label}
                  </Typography>
                )}
                {LabelComponent && <LabelComponent />}
              </div>
            </InputLabel>
          )}
          <InputBase
            id={id}
            ref={ref}
            classes={{
              root: classnames(rootClassName, {
                [classes.inputFocused]: inputFocused,
                [classes.disabled]: disabled,
              }),
              focused: classes.inputFocused,
              input: classnames(classes.input, {
                [classes.inputDisabled]: disabled,
                [inputClassName]: inputClassName,
              }),
            }}
            autoFocus={autoFocus}
            fullWidth={fullWidth}
            defaultValue={defaultValue}
            disabled={disabled}
            error={!!error}
            inputComponent={component}
            readOnly={readOnly}
            required={required}
            onChange={onChange}
            placeholder={placeholder}
            startAdornment={inputStartAdornment}
            endAdornment={inputEndAdornment}
            inputProps={inputProps}
            multiline={multiline}
            rows={rows}
            value={value}
            {...rest}
          />
        </div>
        {getRemainingCharsMessage && inputProps?.maxLength && !disabled && (
          <FormHelperText
            className={classnames(classes.description, {
              [classes.disabled]: disabled,
            })}
          >
            {getRemainingCharsMessage?.(
              `${inputProps.maxLength - currentLength}`,
            )}
          </FormHelperText>
        )}
        {description && !disabled && (
          <InputHelpText
            className={classnames(descriptionClassName, {
              [classes.disabled]: disabled,
            })}
            isError={!!error}
          >
            {description}
          </InputHelpText>
        )}
        {error && (
          <InputHelpText
            className={classnames(descriptionClassName, {
              [classes.disabled]: disabled,
            })}
            isError={!!error}
          >
            {error}
          </InputHelpText>
        )}
      </div>
    );
  },
);

export default LightInput;
