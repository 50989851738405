import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles<Theme>((theme) => ({
  popper: {
    '&[x-placement="bottom-end"] $arrow': {
      top: "-4px",
      right: "19px",
    },
    '&[x-placement="top-end"] $arrow': {
      bottom: "-4px",
      right: "19px",
    },
  },
  container: {
    backgroundColor: theme.palette.background.secondary,
    color: theme.palette.text.primary,
    padding: "5px 0",
    borderRadius: "8px",
    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.5)",
  },
  arrow: {
    position: "absolute",
    transform: "rotate(45deg)",
    width: "10px",
    height: "10px",
    backgroundColor: theme.palette.background.secondary,
    zIndex: -1,
  },
  actionItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "7px",
    cursor: "pointer",
    padding: "12px 20px",
    fontSize: "14px",
    "&:last-child": {
      marginBottom: 0,
    },
    "&:hover": {
      backgroundColor: theme.palette.events.bluePrimary,
      color: theme.palette.events.whiteTertiary,
    },
    "& svg": {
      marginRight: "15px",
      fontSize: "17px",
      "& rect": {
        fill: "none",
      },
    },
  },
}));

export default useStyles;
