import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import isEqual from "lodash/isEqual";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { InputLabel } from "@remo-co/ui-core/src/components/InputLabel";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { SwitchInput } from "@remo-co/ui-core/src/components/SwitchInput";
import { PageLoader } from "@remo-co/ui-core/src/components/PageLoader";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { Info } from "@remo-co/ui-core/src/icons/Info";
import "../../AttendeeListContainer.scss";
import { MANAGE_EVENT_CONTEXT, useAttendeeList } from "modules/manageEvent";
import { useI18n } from "i18n";
import EmailAlert from "modules/eventMemberList/components/EmailAlert";
import { useAttendeeInvite } from "modules/manageEvent/hooks/useAttendeeInvite";
import { IEvent } from "modules/event/types";
import { FormInfo } from "modules/eventForm";
import EventDomain from "modules/eventDomain";
import { ValidatedChipInput } from "@remo-co/ui-core/src/components/ValidatedChipInput";
import { isValidEmailAddress } from "@remo-co/email";
import { EventRole } from "modules/event/constants";
import AttendeeList from "../../AttendeeList";
import AttendeeStats from "../AttendeeStats";
import SendEmailConfirmationPopup from "../SendEmailConfirmationPopup";
import ImportFromCSV from "../ImportFromCSV";
import { useStyles } from "./styles";

const EMAILS_ADD_LIMIT = 200;

interface Props {
  eventData: IEvent;
}

const AttendeeListContainer = ({ eventData }: Props): JSX.Element => {
  const { t } = useI18n([
    "common",
    "event",
    "eventForm",
    "micCamCheck",
    "server",
    "manageEvent",
  ]);
  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();
  const [showAlert, setShowAlert] = useState(false);
  const [emailsInvalid, setEmailsInvalid] = useState(false);
  const { invitedAttendees, isFetching, refetch } = useAttendeeList(
    eventData.id,
    false,
    true,
    EventRole.attendee,
  );
  const styles = useStyles();
  const { isUnlimitedEvent, sendEmailInvites = true } = eventData;
  const { sendInviteAsync, isPending } = useAttendeeInvite();
  const { actions, updateEventData, state } = useContext(MANAGE_EVENT_CONTEXT);
  const { pendingAttendeeInvites = [] } = state ?? {};
  const [emails, setEmails] = useState<string[]>(
    Array.from(pendingAttendeeInvites),
  );
  const [isEmailInvite, setIsEmailInvite] = useState(sendEmailInvites);
  const [sendEmailConfirmation, setSendEmailConfirmation] = useState<
    boolean | undefined
  >();

  const [invitationEmails, setInvitationEmails] = useState<string[]>([]);

  const isPrivate = eventData && eventData.isPrivate;

  const handleInviteAttendee = useCallback(
    async ({
      isEmailInvitation = true,
      emailsToInvite,
    }: {
      isEmailInvitation?: boolean;
      emailsToInvite: string[];
    }) => {
      try {
        const resp = await sendInviteAsync({
          eventId: eventData.id,
          emails: emailsToInvite,
          force: false,
          isEmailInvitation,
        });

        if (resp.isSuccess) {
          setShowAlert(false);

          addSuccessNotification({
            message: t("event:email.add.list"),
            position: "tr",
            autoDismiss: 5,
          });
          setEmails([]);
          setInvitationEmails([]);
          refetch();
        } else {
          setShowAlert(resp.message === "server:emails.limit.exceeded");
          throw new Error(resp.message);
        }
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : "something.wrong";
        addErrorNotification({
          message: t(errorMessage),
          position: "tc",
          autoDismiss: 5,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [emails, eventData.id, refetch, sendInviteAsync],
  );

  const handleSendEmails = useCallback(async () => {
    if (emailsInvalid) {
      return;
    }

    if (!isEmailInvite) {
      setSendEmailConfirmation(true);
      setInvitationEmails(emails);
    } else {
      handleInviteAttendee({ emailsToInvite: emails });
    }
    setEmails([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData, isEmailInvite, emails]);

  const handleSendEmailInvite = useCallback(() => {
    updateEventData({ ...eventData, sendEmailInvites: !isEmailInvite });
    setIsEmailInvite(!isEmailInvite);
    actions?.setPendingAttendeeInvites(emails, !isEmailInvite);
  }, [isEmailInvite, updateEventData, eventData, actions, emails]);

  const isChecked =
    eventData.sendReminders !== undefined ? eventData.sendReminders : true;
  const onRemindersChange = async (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    updateEventData({ ...eventData, sendReminders: checked });
  };

  const validateGuestView = useCallback(() => {
    if (emails && emails.length && eventData.id) {
      return {
        isValid: false,
        title: t("event:ask.sure"),
        message: t("event:ask.invite.friends"),
        confirmText: t("event:confirm.invite.friends"),
        cancelText: t("common:no.skip.it"),
        onConfirm: handleSendEmails,
      };
    }

    return { isValid: true };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emails, handleSendEmails]);

  const onInputChange = (updatedEmails: string[]) => {
    setEmails(updatedEmails);

    if (
      !eventData?.id &&
      !emailsInvalid &&
      !isEqual(Array.from(pendingAttendeeInvites).sort(), updatedEmails.sort())
    ) {
      actions?.setPendingAttendeeInvites(emails, isEmailInvite);
    }
  };

  useEffect(() => {
    actions?.setTabValidator(validateGuestView);

    return () => {
      actions?.setTabValidator(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateGuestView]);

  const handleConfirmPopupClose = (isSendInvite: boolean) => {
    setSendEmailConfirmation(undefined);
    handleInviteAttendee({
      isEmailInvitation: isSendInvite,
      emailsToInvite: invitationEmails,
    });
  };

  const disableSendEmailsButton =
    emails.length === 0 ||
    emails.length > EMAILS_ADD_LIMIT ||
    isPending ||
    emailsInvalid;

  return (
    <div className="attendee-list-ctn">
      <Typography variant="h2">{t("micCamCheck:invite.guests")}</Typography>
      <Typography variant="body1" mb={2}>
        {t("micCamCheck:invite.guests.info")}
      </Typography>
      {!eventData.id && <FormInfo text={t("event:email.auto.send")} />}
      {isPrivate && (
        <EventDomain updateEventData={updateEventData} eventData={eventData} />
      )}
      <Box>
        {!isUnlimitedEvent && (
          <Box marginBottom="10px">
            <SwitchInput
              label={t("event:event.reminder.mail.title")}
              data-testid="reminder-change"
              checked={isUnlimitedEvent ? false : isChecked}
              onChange={onRemindersChange}
            />
            <Tooltip
              placement="top"
              className="help-hint "
              title={t("event:email.reminder.schedule") ?? ""}
            >
              <Info style={{ verticalAlign: "middle" }} color="primary" />
            </Tooltip>
          </Box>
        )}
        <Box marginBottom="20px">
          <InputLabel className={styles.inputLabel}>
            {t("guest.emails")}
          </InputLabel>
          <ValidatedChipInput
            value={emails}
            validate={(hasSomeInvalid) => setEmailsInvalid(hasSomeInvalid)}
            onChange={onInputChange}
            limit={EMAILS_ADD_LIMIT}
            placeholder={t("add.email.placeholder")}
            invalidInputsMessage={t("email.invalid")}
            getRemainingMessage={(key) => t("email.remaining", { key })}
            getExceededMessage={(key) => t("email.exceeded", { key })}
            customValidator={isValidEmailAddress}
            theme="light"
          />
          <ImportFromCSV onImport={setEmails} limit={EMAILS_ADD_LIMIT} />
        </Box>
        {showAlert && <EmailAlert />}
        {eventData.id && (
          <Box justifyContent="flex-end" display="flex" marginBottom="5px">
            <Button
              data-testid="send-emails-button"
              variant="secondary"
              color="blue"
              contentClassName={styles.addSpeakerButtonContent}
              onClick={handleSendEmails}
              disabled={disableSendEmailsButton}
              theme="light"
            >
              {t("event:add.guest.list")}
            </Button>
          </Box>
        )}
        <SwitchInput
          checked={isEmailInvite}
          id="email-invitation"
          onChange={handleSendEmailInvite}
          label={t("event:label.invitation.send")}
        />
      </Box>
      {(isFetching || isPending) && (
        <div className="loading-div">
          <PageLoader fullScreen message={t("wait")} />
        </div>
      )}
      {!isFetching && !!invitedAttendees.length && (
        <>
          <Typography className="mar-bottom-10 mar-top-20" variant="h3">
            {t("event:guest.list")}
          </Typography>
          <AttendeeStats attendees={invitedAttendees} />
          <Box className="mar-bottom-20">
            <AttendeeList
              attendeeData={invitedAttendees}
              getAttendeeData={refetch}
              isFetchingAttendeeData={isFetching}
              eventData={eventData}
              setEmails={setEmails}
            />
          </Box>
        </>
      )}
      {!!eventData?.id && sendEmailConfirmation && (
        <SendEmailConfirmationPopup onClose={handleConfirmPopupClose} />
      )}
    </div>
  );
};

export default AttendeeListContainer;
